import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SET_LOGIN } from '../../constants/store'

import { Dialog, DialogTitle, Button } from '@material-ui/core'
import { AccountCircleRounded as AccountCircleRoundedIcon } from '@material-ui/icons'

import Home from '../home'
import About from '../about'
import Demo from '../demo'
import Game from '../games/CubeGame'
import SpaceGame from '../games/SpaceGame'
import Authentication from '../authentication'
import PatientScreen from '../patient-pages/patient-screen'
import TherapistScreen from '../therapist-pages/therapist-screen'
import NavDrawer from './drawer'

import logo from '../../assets/logo.png'
import './appIndex.css'
import BoardConnection from '../BoardConnection'

const App = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(undefined)
  const [userModal, setUserModal] = useState(false)
  const [navIndicator, setNavIndicator] = useState('Home')
  const patientSelected = useSelector((state) => state.app.selectedPatient)

  useEffect(() => {
    let localUser = JSON.parse(localStorage.getItem('balance-user'))
    if (localUser) {
      setUser(localUser)
      if (localUser.role === 'therapist') {
        setNavIndicator('therapist')
      }
      dispatch({
        type: SET_LOGIN,
        payload: localUser,
      })
    }
  }, [])

  const setUserObject = () => {
    let localUser = JSON.parse(localStorage.getItem('balance-user'))
    if (localUser) {
      setUser(localUser)
    }
  }

  const userClicked = () => {
    setUserModal(true)
    let print = JSON.parse(localStorage.getItem('balance-user'))
    console.log(print)
  }

  const logOut = () => {
    setUser(undefined)
    localStorage.removeItem('balance-user')
  }

  const NavigateTo = (nav) => {
    if (patientSelected) setNavIndicator(nav)
  }

  return (
    <BrowserRouter>
      <div>
        <header className="header-bar">
          {user && (
            <NavDrawer
              onNavClicked={NavigateTo}
              userRole={user.role}
              currentPage={navIndicator}
            />
          )}
          <div className="logo">
            <img className="logo-img" src={logo} alt="logo" />
            <label className="header-label">BalanceBoard</label>
          </div>
          <div style={{ display: 'flex' }}>
            <BoardConnection />
            <div className="user">
              <div className="user-name">{user?.name}</div>
              <AccountCircleRoundedIcon
                style={{ color: 'black', width: '40px', height: '40px' }}
                onClick={() => userClicked()}
              />
            </div>
          </div>
        </header>

        <main>
          {user && navIndicator === 'Home' && <Home />}
          {user && navIndicator === 'About' && <About />}
          {user && navIndicator === 'patient' && <PatientScreen />}
          {user && navIndicator === 'therapist' && (
            <TherapistScreen setNav={setNavIndicator} />
          )}
          {user && navIndicator === 'demo' && <Demo />}
          {user && navIndicator === 'cubeGame' && <Game />}
          {user && navIndicator === 'spaceGame' && <SpaceGame />}
          {!user && <Authentication setUserObject={setUserObject} />}
        </main>
        {user && (
          <Dialog
            onClose={() => setUserModal(false)}
            aria-labelledby="simple-dialog-title"
            open={userModal}>
            <DialogTitle id="simple-dialog-title">Hi {user.name}</DialogTitle>
            <div className="user-dialog">
              <p className="user-dialog-p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <div className="dialog-butttons">
                <Button
                  variant="contained"
                  color="secondary"
                  className="dialog-buttton"
                  onClick={logOut}>
                  Log out
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="dialog-buttton"
                  onClick={() => setUserModal(false)}>
                  close
                </Button>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </BrowserRouter>
  )
}

export default App
