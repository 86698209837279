import {
  RESET_MIN_MAX,
  SET_CONNECTION,
  SET_DISCONNECT,
  SET_MIN_MAX,
  SET_PATIENT,
} from '../../constants/store'
import { DISCONNECTED } from '../../constants/board'

const DEFAULT_MIN_MAX_VALUES = {
  maxX: null,
  maxY: null,
  minX: null,
  minY: null,
}
const initialState = {
  connection: DISCONNECTED,
  minMax: DEFAULT_MIN_MAX_VALUES,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONNECTION:
      return {
        ...state,
        connection: action.payload,
      }
    case SET_DISCONNECT:
      return {
        ...state,
        connection: DISCONNECTED,
      }
    case SET_MIN_MAX:
      return {
        ...state,
        minMax: action.payload,
      }
    case RESET_MIN_MAX:
      return {
        ...state,
        minMax: DEFAULT_MIN_MAX_VALUES,
      }

    default:
      return state
  }
}
