export const createSendMessageFunc = subject => message => subject.next({ message })
export const createSubscriberFunc = subject => cb => subject.asObservable().subscribe(cb) // cb === callback
export const createChannelService = (subject, buffer) => ({
  sendMessage: createSendMessageFunc(subject),
  asyncRecord: (cb, durationInMs = 10 * 1000) => {
    if (buffer.inUse) throw error('already recording another session')
    console.log('startRecording') // todo delete this line - debug

    buffer.inUse = true
    buffer.data = []
    const observable = subject.asObservable().subscribe((message) => {
      if (!Array.isArray(buffer.data)) buffer.data = []

      buffer.data.push({ message, time: Date.now() })

    })
    setTimeout(() => {
      // console.log('setTimeout') // todo delete this line - debug
      observable.unsubscribe()
      cb(buffer.data.slice(0))
      buffer.data = []
      buffer.inUse = false
    }, durationInMs)
  },
  subscribe: createSubscriberFunc(subject),
})
export default createChannelService