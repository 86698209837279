import { Subject } from 'rxjs';
import { BleBoardService } from './BleBoard'
import { SerialBoardService } from './SerialBoard'
import { calcValXValY, calcWeight, calcValXValYFiltered } from '../utils/boardUtils'

const subject = new Subject();

const sendMessage = message => subject.next({ message })
const channel = {
  subscribe: (cb) => subject.asObservable().subscribe(cb), // cb === callback
}
const cb = ({ message: sensorsData }) => {
  sendMessage({ sensors: sensorsData, position: calcValXValY(sensorsData), filteredPosition: calcValXValYFiltered(sensorsData), weight: calcWeight(sensorsData) })
}
const serialObserver = SerialBoardService.subscribe(cb)
const BleObserver = BleBoardService.subscribe(cb)
// todo add ble connection

export default channel