import * as math from "mathjs";
// https://medium.com/@asi_73084/a-better-way-to-work-with-webworkers-part2-4fa8947c391b
export function maxRepCalc(e) {
  // console.log('start', e);
  if (e.data.type == "die") {
    close();
  }
  if (e.data.type == "calc") {
    const x = e.data.x;
    const T = e.data.T;

    // filter data for maximal rep
    const filterData = (b, a, X) => {
      let N = 0;
      let L = 0;
      const Y = [];
      const z = [];

      N = a.length;
      L = X.length;

      // zero prealocation vector
      for (let i = 0; i < X.length; i++) {
        Y[i] = 0;
      }
      // zero prealocation with size of filter length
      for (let i = 0; i < N; i++) {
        z[i] = 0;
      }
      let Xm = 0;
      let Ym = 0;
      for (let m = 0; m < L; m++) {
        Xm = X[m];
        Y[m] = b[0] * Xm + z[0];
        Ym = Y[m];
        for (let i = 1; i < N; i++) {
          z[i - 1] = b[i] * Xm + z[i] - a[i] * Ym;
        }
      }
      // format numbers
      for (let i = 0; i < Y.length; i++) {
        Y[i] = Y[i].toFixed(4);
      }
      return Y;
    };

    const FFT = (x, method) => {
      let X = [];
      let N = 0;
      N = x.length;

      for (let i = 0; i < N; i++) {
        X[i] = 0;
      }
      // fft transform
      if (method == 0) {
        for (let k = 1; k <= N; k++) {
          for (let n = 1; n <= N; n++) {
            X[k - 1] = math.add(
              X[k - 1],
              math.multiply(
                x[n - 1],
                math.exp(
                  math.divide(
                    math.multiply(
                      math.complex("-1i"),
                      2 * math.pi * (n - 1) * (k - 1)
                    ),
                    N
                  )
                )
              )
            );
          }
          if (k % 5 == 0) {
            postMessage({ type: "progress", progress: k / N });
          }
        }
      }
      // fft transform using radix2
      if (method == 1) {
        const N = x.length; // signal length
        const S = math.log2(N); // computing the number of conversion stages
        let Half = 1; // Seting the initial "Half" value

        // Placing the data samples in bit-reversed order
        const revOrder = Array.apply(null, { length: N })
          .map(Number.call, Number)
          .map((i) =>
            parseInt(
              (i >>> 0)
                .toString(2)
                .padStart(S, "0")
                .split("")
                .reverse()
                .join(""),
              2
            )
          )
          .map((i) => Number(x[i]));
        x = revOrder;

        for (let stage = 1; stage <= S; stage++) {
          // stages of transformation
          for (let index = 0; index <= N - 1; index += math.pow(2, stage)) {
            // series of "butterflies" for each stage
            for (let n = 0; n <= Half - 1; n++) {
              // creating "butterfly" and saving the results
              const pos = n + index; // index of the data sample
              const pow = n * math.pow(2, S - stage); // part of power of the complex multiplier
              const w = math.exp(
                math.divide(
                  math.multiply(math.complex("-1i"), 2 * math.pi, pow),
                  N
                )
              ); // complex multiplier
              const a = math.add(x[pos], math.multiply(x[pos + Half], w)); // 1-st part of the "butterfly" creating operation
              const b = math.add(x[pos], math.multiply(x[pos + Half], w, -1)); // 2-nd part of the "butterfly" creating operation
              x[pos] = a; // saving computation of the 1-st part
              x[pos + Half] = b; // saving computation of the 2-nd part
            }
          }
          Half = 2 * Half; // computing the next "Half" value
          postMessage({ type: "progress", progress: stage / S });
        }
        X = x;
      }

      // Removing complex values {X.*conj(X)/N}
      X = X.map((Xi) => (Xi.re * Xi.re + Xi.im * Xi.im) / N);

      // format numbers
      X = X.map((Xi) => Xi.toFixed(4));

      return X;
    };

    // Calculates a single frequency for maximal spectral energy in a specific time duration
    const GetMaxFreqEnergy = (x, T) => {
      const NFFT = 256;
      let L = 0;
      let Fs = 0;
      let x_filt = 0;
      let d = 1;
      const x_new = [];
      let L_new = 0;
      let Y = [];
      const Y_new = [];
      const f = [];
      let max_index = -1;
      
      L = x.length;
      Fs = Math.floor(L / T);
      // console.log('l / t', L / T);

      let sum_x = 0;
      let avg_x = 0;

      // remove DC component
      for (let i = 0; i < L; i++) {
        sum_x += x[i];
      }
      avg_x = sum_x / L;

      for (let i = 0; i < L; i++) {
        x[i] = x[i] - avg_x;
      }

      const b = [
        0.962633797057608,
        -2.887901391172823,
        2.887901391172823,
        -0.962633797057608,
      ];
      const a = [1, -2.92384477360852, 2.850561775658975, -0.926663827193367];

      x_filt = filterData(b, a, x);
      x = x_filt;

      if (L >= NFFT) {
        d = Math.ceil(L / NFFT);
        x_new[Math.ceil(L / NFFT)] = 0;
        let j = 0;
        // down sample the signal with delta of d
        for (let i = 0; i < L; ) {
          x_new[j] = x[i];
          j += 1;
          i += d;
        }
        L_new = x_new.length;
        // Zero padding until NFFT
        for (let i = L_new; i < NFFT; i++) {
          x_new[i] = 0;
        }
        x = [];
        for (let i = 0; i < x_new.length; i++) {
          x[i] = x_new[i];
        }
      }
      // L < NFFT
      else {
        // Zero padding until NFFT
        for (let i = L; i < NFFT; i++) {
          x[i] = 0;
        }
      }

      // FFT transform
      Y = FFT(x, 1);

      // PSD of FFT
      for (let i = 0; i < NFFT / 2 + 1; i++) {
        Y_new[i] = 2 * Math.abs(Y[i]);
      }

      // Generating frequency vector & Reducing frequency vector by factor d
      for (let i = 0; i < NFFT / 2 + 1; i++) {
        f[i] = (((Fs / d / 2) * i) / (NFFT / 2 + 1)).toFixed(1);
      }
      
      // find which index holds max value of Y
      let max_value = 0;
      for (let i = 0; i < Y_new.length; i++) {
        if (Y_new[i] > max_value) {
          max_value = Y_new[i];
          max_index = i;
        }
      }
      // console.log('FFT array:', Y);
      // console.log('PSD of FFT array:', Y_new);
      // console.log('frequency vector reduced by factor d:', f);
      // console.log('frequency with maximal spectral energy', f[max_index])
      return f[max_index];
      // post the frequency with maximal spectral energy
      postMessage({ type: "result", maxFreq: f[max_index] });
    };

    return GetMaxFreqEnergy(x, T);
  }
};
