import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SET_LOGIN } from '../../constants/store'
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import logo from '../../assets/logo.png'
import './authentication.css'

const Authentication = ({ setUserObject }) => {
  const dispatch = useDispatch()
  const [signInMode, setSignInMode] = useState(true)
  const [isTherapist, setIsTherapist] = useState(false)
  const [signInData, setSignInData] = useState({ email: 'email', password: '' })
  const [signUpData, setSignUpData] = useState({
    name: 'name',
    email: 'email',
    password_1: '',
    password_2: '',
  })
  const [showError, setShowError] = useState(false)
  const [errorLabel, setErrorLabel] = useState(
    'something went wrong, please try again'
  )

  const onSignInChangeFunc = (e) => {
    setSignInData({ ...signInData, [e.target.name]: [e.target.value] })
  }

  const onSignUpChangeFunc = (e) => {
    setSignUpData({ ...signUpData, [e.target.name]: [e.target.value] })
  }

  const raiseLoginError = (text) => {
    setErrorLabel(text)
    setShowError(true)
  }

  const setUserToStorage = (user) => {
    if (user.role) {
      localStorage.setItem('balance-user', JSON.stringify(user))
      dispatch({
        type: SET_LOGIN,
        payload: user,
      })
      setUserObject()
    } else {
      raiseLoginError(user.message)
      setErrorLabel(user.message)
      setShowError(true)
    }
  }

  const signinClicked = async () => {
    setShowError(false)

    let myHeaders = new Headers().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    )
    let urlencoded = new URLSearchParams()
    urlencoded.append('email', signInData.email)
    urlencoded.append('password', signInData.password)
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    }
    await fetch('https://bb.chepa.net/v1/users/login', requestOptions)
      .then((response) => response.json())
      .then((result) => setUserToStorage(result))
      .catch((error) => console.log('error', error))
  }

  const signupClicked = () => {
    setShowError(false)
    console.log(
      `signin clicked with name: ${signUpData.name}, email: ${signUpData.email} first pass: ${signUpData.password_1[0]} sec pass: ${signUpData.password_2}`
    )
    if (signUpData.password_1[0] != signUpData.password_2[0]) {
      raiseLoginError('password verification failed!!')
      return
    }

    let myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    let raw = JSON.stringify({
      email: signUpData.email[0],
      password: signUpData.password_1[0],
      name: signUpData.name[0],
      role: isTherapist ? 'therapist' : 'patient',
    })
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }
    fetch(
      'https://bb.chepa.net/v1/users?therapist_id=therapist_id',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setUserToStorage(result))
      .catch((error) => console.log('error', error))
  }

  return (
    <div className="auth-container">
      <h1>Welcome to BalanceBoard</h1>
      {signInMode ? (
        <div className="signin-form">
          <div className="avatar">
            <img className="logo-avatar" src={logo} alt="logo" />
          </div>
          <div className="inputs">
            <TextField
              onChange={(e) => onSignInChangeFunc(e)}
              name="email"
              className="input"
              id="email"
              type="email"
              label="Email"
              variant="outlined"
            />
            <TextField
              onChange={(e) => onSignInChangeFunc(e)}
              name="password"
              className="input"
              id="password"
              type="password"
              label="Password"
              variant="outlined"
            />
          </div>
          <div className="error-div">
            {showError && <label className="error-label">{errorLabel}</label>}
          </div>
          <Button
            onClick={signinClicked}
            className="button"
            variant="contained"
            color="primary">
            Sign In
          </Button>
        </div>
      ) : (
        <div className="signup-form">
          <div className="avatar">
            <img className="logo-avatar" src={logo} alt="logo" />
          </div>
          <div className="signup-inputs">
            <TextField
              onChange={(e) => onSignUpChangeFunc(e)}
              name="name"
              className="input"
              id="name"
              type="text"
              label="Full name"
              variant="outlined"
            />
            <TextField
              onChange={(e) => onSignUpChangeFunc(e)}
              name="email"
              className="input"
              id="email"
              type="email"
              label="Email"
              variant="outlined"
            />
            <TextField
              name="address"
              className="wide-input"
              id="address"
              type="text"
              label="Address"
              variant="outlined"
            />
            <TextField
              onChange={(e) => onSignUpChangeFunc(e)}
              name="password_1"
              className="input"
              id="password_1"
              type="password"
              label="Password"
              variant="outlined"
            />
            <TextField
              onChange={(e) => onSignUpChangeFunc(e)}
              name="password_2"
              className="input"
              id="password_2"
              type="password"
              label="Repet password"
              variant="outlined"
            />
            <FormControlLabel
              className="therapist-check-box"
              control={
                <Checkbox
                  checked={isTherapist}
                  onChange={() => setIsTherapist(!isTherapist)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Sign up as therapist"
            />
          </div>
          <div className="error-div">
            {showError && <label className="error-label">{errorLabel}</label>}
          </div>
          <Button
            onClick={signupClicked}
            className="button"
            variant="contained"
            color="primary">
            Sign Up
          </Button>
        </div>
      )}
      <div className="sign-mode" onClick={() => setSignInMode(!signInMode)}>
        <label className="switch-text">
          {signInMode
            ? 'dont have an account yet ? press here to Sign Up'
            : 'Allready have an account ? press here to Sign In'}
        </label>
      </div>
    </div>
  )
}

export default Authentication
