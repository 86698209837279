import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from './app'
import board from './board'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    board,
  })
