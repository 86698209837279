import React from 'react'
import { Button, ButtonGroup } from '@material-ui/core'
import PropTypes from 'prop-types';
import { connectBluetooth, connectSerial, disconnectBoard } from  '../../store/actions/board';
import { Bluetooth as BluetoothIcon, Usb as UsbIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { DISCONNECTED } from '../../constants/board'


const BoardConnection = ({ connection, connectBluetooth, connectSerial, disconnectBoard }) => {
  console.log('connection', connection)

  const handleBleConnect = (e) => {
    e.preventDefault();
    console.log('handleBleConnect')
    connectBluetooth()
  }

  const handleSerialConnect = (e) => {
    e.preventDefault();
    console.log('handleSerialConnect')
    connectSerial()
  }
  const handleDisconnect = (e) => {
    e.preventDefault();
    console.log('handleDisconnect')
    disconnectBoard(connection)
  }

  return (<div>
    {connection !== DISCONNECTED && <Button variant="contained" color="secondary" onClick={handleDisconnect}>Disconnect</Button>}
    {connection === DISCONNECTED && <ButtonGroup disableElevation variant="contained" color="primary">
      <Button onClick={handleBleConnect}><BluetoothIcon/></Button>
      <Button onClick={handleSerialConnect}><UsbIcon/></Button>
    </ButtonGroup>}
  </div>)
}

BoardConnection.propTypes = {
  connection: PropTypes.string.isRequired,
  connectSerial: PropTypes.func.isRequired,
  connectBluetooth: PropTypes.func.isRequired,
  disconnectBoard: PropTypes.func.isRequired,
}

export default connect(state => ({
  connection: state.board.connection,
}), ({ connectBluetooth, connectSerial, disconnectBoard }))(BoardConnection)