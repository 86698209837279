import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Grid,
} from '@material-ui/core'
import ReactApexChart from 'react-apexcharts'
import PropTypes from 'prop-types'

import './graph.css'

const SummeryGraph = ({
  xValAxis,
  yValAxis,
  qAxis,
  xAxis,
  yAxis,
  zAxis,
  timeArray,
  onReturn,
  onSave,
  sessionSaved,
}) => {

  const [showDialog, setShowDialog] = useState()
  const [mouseOnXY, setMouseOnXY] = useState({
      x: 0,
      y: 0
  })

  const getTimeArray = (data) => {
    let secIndicator = Math.ceil(data[0] / 1000 - 1)
    let secArray = []
    let miliArray = []
    data.forEach((item) => {
      miliArray.push([item / 1000])
      if (Math.ceil(item / 1000) > secIndicator) {
        secArray.push(`${Math.floor(item / 1000)}s`)
        secIndicator++
      } else {
        secArray.push('')
      }
    })
    // return ceil sec array
    return secArray
    // return full mili sec array
    // return miliArray;
  }

  const [options, setOptions] = useState({
      chart: {
          id: 'apexchart-example',
          events: {
              click: function(event, chartContext, config) {
                  setMouseOnXY({
                      x: twoAxisGraph[0].data[config.dataPointIndex],
                      y: twoAxisGraph[1].data[config.dataPointIndex]
                  });
              }
          }
      },
      xaxis: {
          // categories: timeArray
          categories: getTimeArray(timeArray),
          title: {
            text: 'Time (sec)',
            offsetX: 0,
            offsetY: -10,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
      },
      yaxis: {
        title: {
          text: 'COP (mm)',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
  });

  const [twoAxisGraph, setTwoAxisGraph] = useState([
    {
      name: 'xAxis',
      data: xValAxis,
    },
    {
      name: 'yAxis',
      data: yValAxis,
    },
  ])

  const onDialogClose = () => {
    setShowDialog(false)
  }
  const handleDialogDrop = () => {
    onReturn()
  }

  return (
    <div>
      <Dialog open={!!showDialog} onClose={onDialogClose}>
        <DialogTitle>Drop Changes</DialogTitle>
        <DialogContent>
          You didn't saved this evaluation. You are going to drop the evaluation
          data. Are you sure you want to drop evaluation data?
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={onDialogClose}>
            Cancel
          </Button>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={handleDialogDrop}>
            Drop
          </Button>
        </DialogActions>
      </Dialog>
      <div className="summery-title">
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="graph-button"
              onClick={() => (sessionSaved ? onReturn() : setShowDialog(true))}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="graph-button"
              disabled={sessionSaved}
              onClick={onSave}>
              {sessionSaved ? 'Data saved successfully' : 'Save Data'}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div
        className="summery-graph"
        style={{ width: `${window.innerWidth}px`, overflowX: 'scroll' }}>
        <ReactApexChart
          options={options}
          series={twoAxisGraph}
          type="line"
          height={500}
          // for scroll width
          // width={`${timeArray.length * 30}px`}
          // for 100% screen width
          width={window.innerWidth - 50}
        />
      </div>
      <div className="summery-actions">
        <div className="board-indicator-container">
          <label>board indicator</label>
          <div className="board-indicator">
            <div 
                className="board-indicator-point"
                style={{position: 'relative', right: mouseOnXY.x, top: mouseOnXY.y}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
SummeryGraph.propTypes = {
  xValAxis: PropTypes.array.isRequired,
  yValAxis: PropTypes.array.isRequired,
  qAxis: PropTypes.array.isRequired,
  xAxis: PropTypes.array.isRequired,
  yAxis: PropTypes.array.isRequired,
  zAxis: PropTypes.array.isRequired,
  timeArray: PropTypes.array.isRequired,
  onReturn: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  sessionSaved: PropTypes.bool.isRequired,
}

export default SummeryGraph
