import { connectBle, disconnectBle } from '../../utils/BleBoard'
import { connectSerial as serialConnect, serialDisconnect } from '../../utils/SerialBoard'
import { RESET_MIN_MAX, SET_CONNECTION, SET_DISCONNECT, SET_MIN_MAX } from '../../constants/store'
import { BLUETOOTH, CABLE } from '../../constants/board'


export const connectBluetooth = () => dispatch => {
  try {
    connectBle(() => {
      dispatch({
        type: SET_CONNECTION,
        payload: BLUETOOTH
      })
    })
  } catch (e) {
    console.log('connectBluetooth error:', e)
  }
}

export const connectSerial = () => dispatch => {
  serialConnect(() => {
    dispatch({
      type: SET_CONNECTION,
      payload: CABLE,
    })
  })
}
export const disconnectBoard = (connection) => dispatch => {
  if (connection === CABLE){
    serialDisconnect(() => dispatch({
      type: SET_DISCONNECT,
    }))
  } else if(connection === BLUETOOTH){
    disconnectBle(() => dispatch({
      type: SET_DISCONNECT,
    }))
  }
}
export const setMinMax = ({ maxX, maxY, minX, minY }) => dispatch => {
  dispatch({
    type: SET_MIN_MAX,
    payload: {
      maxX,
      maxY,
      minX,
      minY,
    }
  })
}
export const resetMinMax = () => dispatch => {
  dispatch({ type: RESET_MIN_MAX })
}