import React, { useState } from 'react';
import clsx from 'clsx';

import PollIcon from '@material-ui/icons/Poll';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupIcon from '@material-ui/icons/Group';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import FlightIcon from '@material-ui/icons/Flight';

import { makeStyles, Drawer, Button, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    }
});

export default function NavDrawer({onNavClicked, userRole, currentPage}) {
    const classes = useStyles();
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        };
        setState({ ...state, [anchor]: open });
    };

    const hanbdleNavClick = page => {
        if (page === 'rehabilitation') {
            alert('not implemented yet');
            return;
        };
        onNavClicked(page);
    };

    const getStyleObj = ind => {
        if (ind === currentPage) {
            return { color: '#3f51b5' };
        };
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {/* <List>
                <ListItem button key={'Home'} onClick={() => hanbdleNavClick('Home')}>
                    <ListItemIcon>
                        <HomeIcon color={currentPage === 'Home' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'Home'} style={getStyleObj('Home')}/>
                </ListItem>
                <ListItem button key={'About'} onClick={() => hanbdleNavClick('About')}>
                    <ListItemIcon>
                        <InfoIcon color={currentPage === 'About' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'About'} style={getStyleObj('About')}/>
                </ListItem>
            </List>
            <Divider /> */}
            <List>
                {userRole === 'therapist' && <ListItem button key={'therapist'} onClick={() => hanbdleNavClick('therapist')}>
                    <ListItemIcon>
                        <GroupIcon color={currentPage === 'therapist' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'My Patients'} style={getStyleObj('therapist')}/>
                </ListItem>}
                {userRole && <ListItem button key={'patient'} onClick={() => hanbdleNavClick('patient')}>
                    <ListItemIcon>
                        <AssignmentIcon color={currentPage === 'patient' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'Patient Records'} style={getStyleObj('patient')}/>
                </ListItem>}
                {userRole && <ListItem button key={'cubeGame'} onClick={() => hanbdleNavClick('cubeGame')}>
                    <ListItemIcon>
                        <CenterFocusStrongIcon color={currentPage === 'cubeGame' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'Cube Game'} style={getStyleObj('cubeGame')}/>
                </ListItem>}
                {userRole && <ListItem button key={'spaceGame'} onClick={() => hanbdleNavClick('spaceGame')}>
                    <ListItemIcon>
                        <FlightIcon color={currentPage === 'spaceGame' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'Space Game'} style={getStyleObj('spaceGame')}/>
                </ListItem>}
                {userRole === 'patient' && <ListItem button key={'rehabilitation'} onClick={() => hanbdleNavClick('rehabilitation')}>
                    <ListItemIcon>
                        <FitnessCenterIcon color={currentPage === 'rehabilitation' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'My Rehabilitation Plan'} style={getStyleObj('rehabilitation')}/>
                </ListItem>}

                {userRole === 'therapist' && <ListItem button key={'demo'} onClick={() => hanbdleNavClick('demo')}>
                    <ListItemIcon>
                        <PollIcon color={currentPage === 'demo' ? 'primary' : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={'Evaluate'} style={getStyleObj('demo')}/>
                </ListItem>}
            </List>
        </div>
    );

    return (
        <div>
        {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
                <MenuIcon/>
            </Button>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
            </React.Fragment>
        ))}
        </div>
    );
}