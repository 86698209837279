import { BrowserSerial } from 'browser-serial'
import { SerialBordChannel } from '../channels'
import { normalizeSensorData } from './boardUtils'

const serial = new BrowserSerial()

export const serialDisconnect = async (cb = null) => {
  if (serial.port) {
    try {
      await serial.disconnect();
      cb && cb();
    } catch (e) {
      console.log('disconnect error')
    }
  }
}

export const sendSerialCommand = async () => {
  if (serial.port) {
    try {
      await serial.write('t');
    } catch (e) {
      console.log('write error')
    }
  }
}
export const connectSerial = async (cb = null) => {
  if (!serial.port) {
    try {
      await serial.connect()
      cb && cb()
      for await (let { value, done } of serial.readLineGenerator()) {
        if (typeof value === 'string') {
          const pointsArray = value
            .replace('@', '')
            .split(' ')
            .map(val => parseInt(val, 10))
          const points = normalizeSensorData({
            x: pointsArray[0],
            y: pointsArray[1],
            z: pointsArray[2],
            q: pointsArray[3]
          })
          SerialBordChannel.sendMessage(points)
        }
        if (done === 'true') {
          break
        }
      }
    } catch (e) {
      console.log('board connection error', e)
    }
  } else {
    console.log('already connected')
  }
};
