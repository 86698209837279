import React, { Component } from 'react'
import { BoardDataChannel } from '../../channels'
import { DISCONNECTED } from '../../constants/board'
import Unity from 'react-unity-webgl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setMinMax } from '../../store/actions/board'

import Menu from '@material-ui/core/Menu'
import Fab from '@material-ui/core/Fab'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'

import GamesIcon from '@material-ui/icons/Games'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import ExposureIcon from '@material-ui/icons/Exposure'
import InfoIcon from '@material-ui/icons/Info'
import { sendCommand } from '../../utils/boardUtils'

class GameContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gameStarted: false,
      valX: undefined,
      valY: undefined,
      minMaxXArray: [],
      minMaxYArray: [],
      minMaxEnabled: false,
      score: 0,
      anchor: undefined,
    }
    this.stopGame = this.stopGame.bind(this)
    this.runMixMax = this.runMixMax.bind(this)
    this.calculateMinMax = this.calculateMinMax.bind(this)
    this.handleBoardData = this.handleBoardData.bind(this)
    this.startGame = this.startGame.bind(this)
    this.isMinMaxSet = this.isMinMaxSet.bind(this)
    this.handleBoardDisconnection = this.handleBoardDisconnection.bind(this)
  }

  componentDidMount() {
    this.BoardData = BoardDataChannel.subscribe(({ message: boardData }) => {
      this.handleBoardData(boardData)
    })

    this.props.unityContext.on('GameOver', (score) => {
      this.setState({ score })
    })
  }
  componentWillUnmount() {
    if (this.BoardData && typeof this.BoardData.unsubscribe === 'function') {
      this.BoardData.unsubscribe()
    }
  }
  isMinMaxSet() {
    const { minMax } = this.props
    return !Object.keys(minMax).find((key) => minMax[key] === null)
  }

  handleBoardData({ filteredPosition }) {
    let { valX, valY } = filteredPosition
    this.setState({ valX, valY })
    const { minMaxEnabled, gameStarted } = this.state

    const { maxX, maxY, minX, minY } = this.props.minMax
    if (minMaxEnabled) {
      const minMaxXArray = this.state.minMaxXArray.slice(0)
      const minMaxYArray = this.state.minMaxYArray.slice(0)
      minMaxXArray.push(valX)
      minMaxYArray.push(valY)
      this.setState({ minMaxXArray, minMaxYArray })
    }

    if (maxX !== 0) {
      valX = (20 / (maxX - minX)) * valX * -1
      valY = (20 / (maxY - minY)) * valY * -1
    }

    if (gameStarted) {
      this.props.unityContext.send('Control', 'setX', valX)
      this.props.unityContext.send('Control', 'setY', valY)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.minMaxEnabled !== prevState.minMaxEnabled &&
      prevState.minMaxEnabled === true
    ) {
      this.calculateMinMax()
    }
    if (
      prevProps.connection !== this.props.connection &&
      this.props.connection === DISCONNECTED
    ) {
      this.handleBoardDisconnection()
    }
  }
  handleBoardDisconnection() {
    // todo handle external disconnection
    console.log('GameContainer board disconnected')
  }

  calculateMinMax() {
    const maxX = Math.max(...this.state.minMaxXArray)
    const maxY = Math.max(...this.state.minMaxYArray)
    const minX = Math.min(...this.state.minMaxXArray)
    const minY = Math.min(...this.state.minMaxYArray)
    this.props.setMinMax({ maxX, maxY, minX, minY })
    console.log(maxX, maxY, minX, minY)
  }

  runMixMax() {
    this.setState({ minMaxEnabled: true, minMaxXArray: [], minMaxYArray: [] })
    setTimeout(() => this.setState({ minMaxEnabled: false }), 5000)
  }

  // resetGame() {
  //   this.props.unityContext.send('Control', 'resetGame')
  // }

  startGame() {
    this.setState({ gameStarted: true })
    this.props.unityContext.send('Control', 'startGame')
  }

  stopGame() {
    if (this.state.status !== 'Stoped') {
      this.setState({ status: 'Stoped' })
      this.setState({ gameStarted: false })
      this.props.unityContext.send('Control', 'stopGame')
    }
  }

  handleClick = (event) => {
    this.setState({ anchor: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchor: null })
  }

  render() {
    const {
      minMax: { maxX, maxY, minX, minY },
      connection,
    } = this.props
    const { gameStarted } = this.state
    const minMaxSet = this.isMinMaxSet()
    const isBoardConnected = connection !== DISCONNECTED
    const disableMinMaxButton = !isBoardConnected || minMaxSet
    const disableSendTareButton = !isBoardConnected || !isBoardConnected
    const disableStopButton = !isBoardConnected || !gameStarted
    const disableStartGameButton =
      !isBoardConnected || !minMaxSet || gameStarted
    const disableReSetGameButton = !isBoardConnected || !gameStarted

    return (
      <div id="app">
        <div className="game-bottons-container">
          <Fab aria-label="add" color="primary" onClick={this.handleClick}>
            <GamesIcon />
          </Fab>
          <Menu
            id="customized-menu"
            anchorEl={this.state.anchor}
            keepMounted
            open={Boolean(this.state.anchor)}
            onClose={this.handleClose}>
            <List disablePadding>
              <ListItem>
                <ListItemText
                  primary={this.state.valX ? 'Streaming' : 'No Stream'}
                  secondary={maxX ? 'Got Range!' : 'No Range!'}
                />
                <Fab color="secondary" aria-label="add">
                  <InfoIcon />
                </Fab>
              </ListItem>
              <ListItem>
                <ListItemText primary={'Min/Max'} />
                <Fab
                  disabled={disableMinMaxButton}
                  color="primary"
                  aria-label="add"
                  onClick={this.runMixMax}>
                  <ExposureIcon />
                </Fab>
              </ListItem>
              <ListItem>
                <ListItemText primary={'Tare'} />
                <Fab
                  color="primary"
                  aria-label="add"
                  disabled={disableSendTareButton}
                  onClick={() => sendCommand(connection)}>
                  <VerticalAlignBottomIcon />
                </Fab>
              </ListItem>
              <ListItem>
                <ListItemText primary={'Start Game'} />
                <Fab
                  color="primary"
                  aria-label="add"
                  disabled={disableStartGameButton}
                  onClick={() => this.startGame()}>
                  <PlayArrowIcon />
                </Fab>
              </ListItem>

              <ListItem>
                <ListItemText primary={'Stop Game'} />
                <Fab
                  color="primary"
                  aria-label="add"
                  disabled={disableReSetGameButton}
                  onClick={() => this.stopGame()}>
                  <StopIcon />
                </Fab>
              </ListItem>
            </List>
          </Menu>
        </div>
        {/* <span style={{ color: '#C20000' }}>
          
          </span> */}
        <div
          style={{
            height: 'calc(100vh - 70px)',
            width: '100%',
            margin: '0px',
            padding: '0px',
            border: '0px',
          }}>
          <Unity
            style={{
              height: '100%',
              width: '100%',
            }}
            unityContext={this.props.unityContext}
          />
        </div>
      </div>
    )
  }
}

GameContainer.propTypes = {
  unityContext: PropTypes.object.isRequired,
  connection: PropTypes.string.isRequired,
  minMax: PropTypes.object.isRequired,
  setMinMax: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    connection: state.board.connection,
    minMax: state.board.minMax,
  }),
  { setMinMax }
)(GameContainer)
