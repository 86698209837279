import { SET_PATIENT, SET_LOGIN } from '../../constants/store'

const initialState = {
  selectedPatient: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload,
      }
    case SET_LOGIN:
      return {
        ...state,
        myLogin: action.payload,
      }

    default:
      return state
  }
}