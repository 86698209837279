import React from 'react'
import * as d3 from 'd3'

class Axis extends React.Component {
  constructor() {
    super()
    this.gRef = React.createRef()
  }

  componentDidUpdate() {
    this.d3Render()
  }

  componentDidMount() {
    this.d3Render()
  }

  d3Render() {
    const { type } = this.props

    d3.select(this.gRef.current).call(d3[`axis${type}`](this.props.scale))
  }
  a
  get labelPos() {
    const { type, scale } = this.props

    switch (type) {
      case 'Top':
        return { x: scale.range()[1] + 20, y: 0 }
      case 'Right':
        return { x: 20, y: 0 }
      case 'Bottom':
        return { x: scale.range()[1] - 15, y: -5 }
      case 'Left':
        return { x: 13, y: -5 }
    }
  }

  render() {
    const { x, y, label } = this.props

    return (
      <g ref={this.gRef} transform={`translate(${x}, ${y})`}>
        <text
          style={{ fill: 'black', fontFamily: 'sans-serif', fontSize: '12px' }}
          {...this.labelPos}>
          {label}
        </text>
      </g>
    )
  }
}

export default Axis
