import React, { Component } from 'react'
import SummeryGraph from './steps/summery-graph.js'
import './steps/graph.css'
import PropTypes from 'prop-types'
import { BoardDataChannel } from '../../channels'
import { DISCONNECTED } from '../../constants/board'
import { connect } from 'react-redux'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import RecordContainer from './steps/RecordContainer'
import RecordConfiguration from './steps/RecordConfiguration'
const statuses = {
  IDLE: 'IDLE',
  RECORDING: 'RECORDING',
  STOPPED: 'STOPPED',
}
export const componentStates = {
  CONFIGURATION: 'CONFIGURATION',
  RECORDING: 'RECORDING',
  SUMMERY: 'SUMMERY',
}

var lastDate = 0
var data = []
var timeArray = []
var xValAxis = []
var yValAxis = []
var qAxis = []
var xAxis = []
var yAxis = []
var zAxis = []
var TICKINTERVAL = 86400000
let XAXISRANGE = 777600000

const stepsNames = {
  steps: [
    {
      title: 'Evaluation Configuration',
    },
    {
      title: 'Start Recording',
    },
    {
      title: 'Save treatment',
    },
  ],
}

class Demo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      x: undefined,
      y: undefined,
      z: undefined,
      q: undefined,
      weight: undefined,
      valX: undefined,
      valY: undefined,
      results: {},
      record_range: 20,
      records_number: 0,
      start_recording: false,
      currentStep: componentStates.CONFIGURATION,
      start_time: undefined,
      left_time: undefined,
      status: statuses.IDLE,
      steps: {
        configuration: {
          title: '',
          feetPos: undefined,
        },
        record: {},
        summery: {
          sessionSaved: false,
        },
      },
    }

    this.onStopClicked = this.onStopClicked.bind(this)
    this.startRecording = this.startRecording.bind(this)
    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.updateRecords = this.updateRecords.bind(this)
    this.handleBoardData = this.handleBoardData.bind(this)
    this.handleConfigurationNext = this.handleConfigurationNext.bind(this)
    this.handleSaveData = this.handleSaveData.bind(this)
    this.onSummeryReturn = this.onSummeryReturn.bind(this)
    this.setSessionSaved = this.setSessionSaved.bind(this)
    this.handleRecordingBack = this.handleRecordingBack.bind(this)
  }
  onSummeryReturn() {
    this.setState((state) => ({
      currentStep: componentStates.CONFIGURATION,
      steps: {
        ...state.steps,
        summery: {
          ...state.steps.summery,
          sessionSaved: false,
        },
      },
    }))
  }

  handleRecordingBack() {
    this.setState((state) => ({
      currentStep: componentStates.CONFIGURATION,
      steps: {
        ...state.steps,
        configuration: {
          title: '',
          feetPos: undefined,
        },
      },
    }))
  }
  handleSaveData() {
    if (!this.state.steps.summery.sessionSaved) {
      const {
        results,
        steps: {
          configuration: { title, feetPos },
        },
      } = this.state
      const test_obg = {
        time: results.timeArray,
        xValAxis: results.xValAxis,
        yValAxis: results.yValAxis,
        qAxis: results.qAxis,
        xAxis: results.xAxis,
        yAxis: results.yAxis,
        zAxis: results.zAxis,
        feetPos: feetPos,
      }
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')
      const body = JSON.stringify({
        patient_id: this.props.selectedPatientId,
        therapist_id: this.props.therapistId,
        date: Date.now(),
        type: 'type',
        results: { title: title },
        evaluation_data: test_obg,
      })
      const options = {
        method: 'POST',
        headers,
        body,
        redirect: 'follow',
      }

      // for local server -->>
      // fetch("http://localhost:3000/v1/evaluation/", options)
      // .then(response => response.text())
      // .then(result => console.log(result))
      // .then(() => setSessionSaved(true))
      // .catch(error => alert('error', error));

      fetch('https://bb.chepa.net/v1/evaluation/', options)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .then(() => this.setSessionSaved(true))
        .catch((error) => alert('error'))
    }
  }

  setSessionSaved() {
    this.setState((state) => ({
      steps: {
        ...state.steps,
        summery: {
          ...state.steps.summery,
          sessionSaved: true,
        },
      },
    }))
  }

  handleConfigurationNext(configuration) {
    this.setState((state) => ({
      currentStep: componentStates.RECORDING,
      steps: {
        ...state.steps,
        configuration: {
          ...state.configuration,
          ...configuration,
        },
      },
    }))
  }

  componentDidMount() {
    this.BoardData = BoardDataChannel.subscribe(({ message: boardData }) => {
      this.handleBoardData(boardData)
    })
  }

  componentWillUnmount() {
    if (this.BoardData && typeof this.BoardData.unsubscribe === 'function') {
      this.BoardData.unsubscribe()
    }
  }

  startRecording() {
    console.log('start recording')
    this.setState({ start_recording: true })
    if (this.state.status !== statuses.RECORDING) {
      this.setState({ status: statuses.RECORDING })
    }
  }

  updateRecords({ x, y, z, q, valX, valY, testTime }) {
    xValAxis.push(valX)
    yValAxis.push(valY)
    qAxis.push(q)
    xAxis.push(x)
    yAxis.push(y)
    zAxis.push(z)
    timeArray.push(testTime)
    this.setState((state) => ({ records_number: state.records_number + 1 }))
  }

  handleBoardData({
    sensors: { x, y, z, q },
    position: { valX, valY },
    weight,
  }) {
    if (!this.state.start_time && this.state.start_recording) {
      let time_now = Date.now()
      this.setState({ start_time: time_now })
    }

    this.setState({ valX, valY, x, y, z, q, weight })

    if (this.state.start_recording) {
      let test_time = Date.now() - this.state.start_time
      this.setState({ left_time: test_time })
      if (test_time <= this.state.record_range * 1000) {
        this.updateRecords({ x, y, z, q, valX, valY, testTime: test_time })
      }
      if (test_time > this.state.record_range * 1000) {
        // end test
        this.onStopClicked()
      }
    }
    var newDate = lastDate + TICKINTERVAL
    lastDate = newDate

    data.push({
      x: newDate,
      y: this.state?.valX ?? 10,
    })

    for (var i = 0; i < data.length - 10; i++) {
      // IMPORTANT
      // we reset the x and y of the data which is out of drawing area
      // to prevent memory leaks
      data[i].x = newDate - XAXISRANGE - TICKINTERVAL
      data[i].y = 0
    }
  }

  onStopClicked() {
    if (this.state.status !== statuses.STOPPED) {
      this.setState({ status: statuses.STOPPED })
    }

    // move to summery graph
    this.setState((state) => ({
      status: statuses.IDLE,
      start_recording: false,
      start_time: undefined,
      left_time: undefined,
      currentStep: componentStates.SUMMERY,
      results: {
        xValAxis,
        yValAxis,
        xAxis,
        qAxis,
        yAxis,
        zAxis,
        timeArray,
        start_time: state.start_time,
      },
    }))
    xValAxis = []
    yValAxis = []
    qAxis = []
    xAxis = []
    yAxis = []
    zAxis = []
    timeArray = []
  }

  handleSliderChange(event, newValue) {
    this.setState({ record_range: newValue })
  }

  getPage = (currentStep) => {
    switch (currentStep) {
      case componentStates.CONFIGURATION:
        return 0;
      case componentStates.RECORDING:
        return 1;
      case componentStates.SUMMERY:
        return 2;
      default:
        return 0;
    }
  };

  render() {
    const disableStartRecording = this.props.connection === DISCONNECTED || this.state.status === statuses.RECORDING
    let {
      results: { xValAxis, yValAxis, qAxis, xAxis, yAxis, zAxis, timeArray },
      steps: {
        summery: { sessionSaved },
      },
    } = this.state
    return (
      <React.Fragment>
        <Stepper activeStep={this.getPage(this.state.currentStep)}>
          {stepsNames.steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {this.state.currentStep === componentStates.CONFIGURATION && (
          <RecordConfiguration onNext={this.handleConfigurationNext} />
        )}
        {this.state.currentStep === componentStates.RECORDING && (
          <RecordContainer
            disableStartRecording={disableStartRecording}
            startRecording={this.startRecording}
            onStopClicked={this.onStopClicked}
            record_range={this.state.record_range}
            handleSliderChange={this.handleSliderChange}
            valX={this.state.valX}
            valY={this.state.valY}
            weight={this.state.weight}
            disableReturn={this.state.status === statuses.RECORDING}
            onReturn={this.handleRecordingBack}
            left_time={this.state.left_time}
            status={this.state.status}
          />
        )}
        {this.state.currentStep === componentStates.SUMMERY && (
          <SummeryGraph
            xValAxis={xValAxis}
            yValAxis={yValAxis}
            qAxis={qAxis}
            xAxis={xAxis}
            yAxis={yAxis}
            zAxis={zAxis}
            timeArray={timeArray}
            onReturn={this.onSummeryReturn}
            onSave={this.handleSaveData}
            sessionSaved={sessionSaved}
          />
        )}
      </React.Fragment>
    )
  }
}
Demo.propTypes = {
  connection: PropTypes.string.isRequired,
}
export default connect((state) => ({
  connection: state.board.connection,
  selectedPatientId: state.app.selectedPatient.id,
  therapistId: state.app.myLogin.id,
}))(Demo)
