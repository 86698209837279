import React, { useState } from 'react'
import { Button, TextField, Grid } from '@material-ui/core'

import './graph.css'

export default function RecordConfiguration({ onNext }) {
  const [title, setTitle] = useState('')
  const [feetPos, setFeetPose] = useState()
  return (
    <Grid container>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center">
        <Grid item >
          <TextField
            fullWidth
            label="Enter measurement title"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item >
          <div className="feet-pos-container">
            <label className="feet-title">
              {feetPos ? feetPos : 'Select feet position'}
            </label>
            <div className="feet-radios">
              <div
                className="away-pos"
                onClick={() => setFeetPose('away')}
                style={{
                  border:
                    feetPos === 'away' ? '3px solid cyan' : '3px solid #c3c3c3',
                }}
              />
              <div
                className="normal-pos"
                onClick={() => setFeetPose('normal')}
                style={{
                  border:
                    feetPos === 'normal'
                      ? '3px solid cyan'
                      : '3px solid #c3c3c3',
                }}
              />
              <div
                className="halfTandam-pos"
                onClick={() => setFeetPose('tandam')}
                style={{
                  border:
                    feetPos === 'tandam'
                      ? '3px solid cyan'
                      : '3px solid #c3c3c3',
                }}
              />
              <div
                className="close-pos"
                onClick={() => setFeetPose('close')}
                style={{
                  border:
                    feetPos === 'close'
                      ? '3px solid cyan'
                      : '3px solid #c3c3c3',
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            color="primary"
            className="graph-button"
            onClick={() => {
              onNext({ feetPos, title })
            }}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
