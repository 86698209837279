//calculating area from hull points
export default function area(dots, signed) {
  var l = dots.length
  var det = 0
  var isSigned = signed || false

  dots = dots.map(normal)
  if (dots[0] != dots[dots.length - 1]) dots = dots.concat(dots[0])

  for (var i = 0; i < l; i++)
    det += dots[i].x * dots[i + 1].y - dots[i].y * dots[i + 1].x
  if (isSigned) return det / 2
  else return Math.abs(det) / 2
}

function normal(point) {
  if (Array.isArray(point))
    return {
      x: point[0],
      y: point[1],
    }
  else return point
}
