import KalmanFilter from 'kalmanjs'
import { BLUETOOTH, CABLE } from '../constants/board'
import { sendBleCommand } from './BleBoard'
import { sendSerialCommand } from './SerialBoard'

const kf = new KalmanFilter()

const NORMALIZE_VALUE = 2000

export const calcValXValY = ({ x, y, z, q }) => ({
  valX: parseFloat(((395 / calcWeight({ x, y, z, q })) * calcVal(y, x, q, z)).toFixed(2)),
  valY: parseFloat(((260 / calcWeight({ x, y, z, q })) * calcVal(z, x, q, y) * -1).toFixed(2)),
})

export const calcValXValYnotmm = ({ x, y, z, q }) => ({
  valX: calcVal(y, x, q, z),
  valY: calcVal(z, x, q, y),
})

const calcVal = (s1, s2, s3, s4) => (s1 - s2 + (s3 - s4)) / 2000

export const calcValXValYFiltered = ({ x, y, z, q }) => ({
  valX: kf.filter(calcVal(y, x, q, z)),
  valY: kf.filter(calcVal(z, x, q, y)),
})

export const calcWeight = ({ x, y, z, q }) =>
  (Math.abs(x) + Math.abs(y) + Math.abs(z) + Math.abs(q)) / 1000

export const normalizeSensorData = ({ x, y, z, q }) => ({
  x: x - NORMALIZE_VALUE,
  y: y - NORMALIZE_VALUE,
  z: z - NORMALIZE_VALUE,
  q: q - NORMALIZE_VALUE,
})

export const buf2hex = (buffer) => {
  let decodedBuf = []
  let num = 0
  const int8Arr = new Uint8Array(buffer)
  int8Arr.forEach((val, index) => {
    if (index % 2) {
      // lower part => index % 2 == 1
      num += val
      decodedBuf.push(num)
      num = 0
    } else {
      num += val * 255
    }
  })
  return decodedBuf
}
export const hexToBuf = (hex) => {
  const buffer = new ArrayBuffer(hex.length / 2)
  let array = new Uint8Array(buffer)
  let k = 0
  for (let i = 0; i < hex.length; i += 2) {
    array[k] = parseInt(hex[i] + hex[i + 1], 16)
    k++
  }
  return buffer
}

export const sendCommand = (connection) => {
  connection === BLUETOOTH
    ? sendBleCommand('00')
    : (connection === CABLE
      ? sendSerialCommand()
      : console.log('board is disconnected, can\'t send command'))
}