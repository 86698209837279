import React, { Fragment } from 'react'
import { Button, Slider, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import Scatterplot from './components/Scatterplot'
import { connect } from 'react-redux'
import { sendCommand } from '../../../utils/boardUtils'

const marks = [
  {
    value: 5,
    label: '5s',
  },
  {
    value: 10,
    label: '10s',
  },
  {
    value: 20,
    label: '20s',
  },
  {
    value: 30,
    label: '30s',
  },
  {
    value: 40,
    label: '40s',
  },
  {
    value: 50,
    label: '50s',
  },
  {
    value: 60,
    label: '60s',
  },
]

const RecordContainer = ({
  valX,
  valY,
  disableStartRecording,
  startRecording,
  left_time = 20,
  onStopClicked,
  record_range,
  handleSliderChange,
  weight,
  onReturn,
  disableReturn,
  status,
  connection,
}) => {
  return (
    <div id="app" className="graph-container">
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className="graph-button"
            disabled={disableReturn}
            onClick={onReturn}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={disableStartRecording}
            onClick={() => sendCommand(connection)}
            variant="contained"
            color="primary"
            className="graph-button">
            Tare
          </Button>
        </Grid>
        {/* <Grid item>
          <Button
            onClick={() => onStopClicked()}
            variant="contained"
            color="primary"
            className="graph-button">
            Stop
          </Button>
        </Grid> */}
        <Grid item style={{ width: '20%' }}>
          {status != 'RECORDING' ? (
            <Fragment>
              <Typography className="record-time-timer" gutterBottom>
                Record Time
              </Typography>
              <Slider
                value={record_range}
                onChange={handleSliderChange}
                step={5}
                aria-labelledby="discrete-slider-always"
                valueLabelDisplay="auto"
                defaultValue={20}
                max={60}
                min={5}
                marks={marks}
                getAriaValueText={() => `${record_range}`}
              />
            </Fragment>
          ) : (
            <div className="record-time">
              {`Time Left: ${Math.ceil(record_range - left_time / 1000)}s`}
            </div>
          )}
        </Grid>

        <Grid item>
          <Button
            disabled={disableStartRecording}
            onClick={() => startRecording()}
            variant="contained"
            color="primary"
            className="graph-button">
            Start Recording
          </Button>
        </Grid>
      </Grid>

      {/* <label className="debug-label">
        Weight: {weight}KG
        <br />
        X:{valX}
        <br />
        Y:
        {valY}
      </label> */}

      <Scatterplot
        x={50}
        y={50}
        width={1850}
        height={500}
        data={[[valX ?? 0, valY ?? 0]]}
        // hull={true}
      />
    </div>
  )
}
const BOARD_DATA_TYPE = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
])
RecordContainer.propTypes = {
  connection: PropTypes.string.isRequired,
  disableStartRecording: PropTypes.bool.isRequired,
  disableReturn: PropTypes.bool.isRequired,
  startRecording: PropTypes.func.isRequired,
  onStopClicked: PropTypes.func.isRequired,
  record_range: PropTypes.number.isRequired,
  handleSliderChange: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  valX: BOARD_DATA_TYPE,
  weight: BOARD_DATA_TYPE,
  valY: BOARD_DATA_TYPE,
}

export default connect((state) => ({
  connection: state.board.connection,
}))(RecordContainer)
