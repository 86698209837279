import { Subject } from 'rxjs';
import { createChannelService } from './createChannelService'
const buffer = {
  data: [],
  inUse: false,
}

const subject = new Subject();

export const BleBoardService = createChannelService(subject, buffer)
