import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  Grid,
} from '@material-ui/core'
import hull from 'hull.js'
import area from '../../utils/hull_calc'

import { maxRepCalc } from '../maxRepCalc'

import './patient-screen.css'

import Scatterplot from '../demo/steps/components/Scatterplot'

const columns = [
  { id: 'index', label: '' },
  { id: 'Date', label: 'Date' },
  { id: 'Title', label: 'Title' },
  { id: 'fft-x', label: 'Frequency X' },
  { id: 'fft-y', label: 'Frequency Y' },
  { id: 'hull', label: 'Sway area' },
]

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

const PatientScreen = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [polygon, setPolygon] = useState(undefined)
  const [polygon2, setPolygon2] = useState(undefined)
  const [graphSeries, setGraphSeries] = useState(false)
  const [graphOptions, setGraphOptions] = useState(false)
  const [patientEvaluations, setPatientEvaluations] = useState(undefined)
  const [selectedEvals, setSelectedEvals] = useState([])
  const [showSelectedEvalsGraph, setShowSelectedEvalsGraph] = useState(false)
  const [polygonsTitle, setPolygonsTitle] = useState(undefined)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (selectedEvals?.length > 0) {
      showGraphs()
    }
  }, [selectedEvals])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getTimeArray = (data) => {
    let secIndicator = Math.ceil(data[0] / 1000 - 1)
    let secArray = []
    let miliArray = []
    data.forEach((item) => {
      miliArray.push(item / 1000)
      if (Math.ceil(item / 1000) > secIndicator) {
        secArray.push(`${Math.floor(item / 1000)}s`)
        secIndicator++
      } else {
        secArray.push('')
      }
    })
    return secArray
  }

  const fetchData = async () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }
    fetch(
      'https://bb.chepa.net/v1/evaluation?patient_id=a123ebc789dcbABCD7894561',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setPatientEvaluations(result))
      .catch((error) => alert('error', error))
  }

  const getFFTXMAxRepObjectWithIndex = (index) => {
    const timeArr = patientEvaluations[index].evaluation_data.time
    const dataObj = {
      data: {
        type: 'calc',
        x: patientEvaluations[index].evaluation_data.xValAxis,
        T: (timeArr[timeArr.length - 1] - timeArr[0]) / 1000,
      },
    }
    const result = maxRepCalc(dataObj)
    return result === undefined ? 'calc error' : `${result} Hertz`
  }

  const getFFTYMAxRepObjectWithIndex = (index) => {
    const timeArr = patientEvaluations[index].evaluation_data.time
    const dataObj = {
      data: {
        type: 'calc',
        x: patientEvaluations[index].evaluation_data.yValAxis.map((val) =>
          parseFloat(val)
        ),
        T: (timeArr[timeArr.length - 1] - timeArr[0]) / 1000,
      },
    }
    const result = maxRepCalc(dataObj)
    return result === undefined ? 'calc error' : `${result} Hertz`
  }

  const getHullAreaWithIndex = (index) => {
    let polygons = []
    for (
      let i = 0;
      i < patientEvaluations[index].evaluation_data.xAxis.length;
      i++
    ) {
      polygons.push([
        parseFloat(patientEvaluations[index].evaluation_data.xValAxis[i]),
        parseFloat(patientEvaluations[index].evaluation_data.yValAxis[i]),
      ])
    }
    const result = area(hull(polygons))

    return result
  }

  const getIsCheckboxSelected = (id) => {
    return selectedEvals.includes(id) ? true : false
  }

  const handleSelection = (id) => {
    setShowSelectedEvalsGraph(false)
    let temp = []
    selectedEvals.forEach((elem) => {
      temp.push(elem)
    })
    if (!temp.includes(id)) {
      temp.push(id)
    } else {
      let deletIndex = temp.indexOf(id)
      temp.splice(deletIndex, 1)
    }
    setSelectedEvals(temp)
  }

  const clearSelection = () => {
    setSelectedEvals([])
    setPolygon([])
    setPolygon2([])
    setPolygonsTitle([])
    setShowSelectedEvalsGraph(false)
  }

  const showGraphs = () => {
    const graphDataObj = {
      options: {
        chart: {
          id: 'apexchart-example',
        },
        xaxis: {
          categories: getTimeArray(
            patientEvaluations[selectedEvals[0]].evaluation_data.time
          ),
          title: {
            text: 'Time (sec)',
            offsetX: 0,
            offsetY: -10,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
        yaxis: {
          title: {
            text: 'COP (mm)',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
      },
      twoAxisGraph: [],
    }
    selectedEvals.forEach((elem) => {
      graphDataObj.twoAxisGraph.push({
        name: `${patientEvaluations[elem].results.title} xAxis`,
        data: patientEvaluations[elem].evaluation_data.xValAxis.map((elem) =>
          elem.toFixed(2)
        ),
      })
      graphDataObj.twoAxisGraph.push({
        name: `${patientEvaluations[elem].results.title} yAxis`,
        data: patientEvaluations[elem].evaluation_data.yValAxis,
      })
    })
    setGraphOptions(graphDataObj.options)
    setGraphSeries(graphDataObj.twoAxisGraph)
    setShowSelectedEvalsGraph(true)

    let polygon1 = []
    let polygon2 = []
    for (
      let i = 0;
      i < patientEvaluations[selectedEvals[0]].evaluation_data.xAxis.length;
      i++
    ) {
      polygon1.push([
        patientEvaluations[selectedEvals[0]].evaluation_data.xValAxis[i],
        patientEvaluations[selectedEvals[0]].evaluation_data.yValAxis[i],
      ])
      if (selectedEvals.length > 1) {
        polygon2.push([
          patientEvaluations[selectedEvals[1]].evaluation_data.xValAxis[i] - 10,
          patientEvaluations[selectedEvals[1]].evaluation_data.yValAxis[i] + 10,
        ])
      }
    }
    setPolygon(polygon1)
    setPolygon2(polygon2)
    if (selectedEvals.length > 1) {
      setPolygonsTitle([
        patientEvaluations[selectedEvals[0]].results.title,
        patientEvaluations[selectedEvals[1]].results.title,
      ])
    } else {
      setPolygonsTitle([patientEvaluations[selectedEvals[0]].results.title])
    }
  }

  useEffect(() => {
    if (!patientEvaluations) {
      fetchData()
    }
  }, [patientEvaluations])

  return (
    <div className="therapist-container">
      <div className="patient-title">
        Patient Records
        <div className="patient-title-buttons">
          {/* {selectedEvals.length ? (
            <Button variant="contained" color="primary" onClick={showGraphs}>
              Show Graphs
            </Button>
          ) : null} */}
          {selectedEvals.length ? (
            <Button
              variant="contained"
              color="primary"
              onClick={clearSelection}>
              Clear
            </Button>
          ) : null}
        </div>
      </div>
      <Grid container>
        <Grid item lg="5">
          {patientEvaluations && (
            <TableContainer
              className="patient-table"
              style={{ height: '1000px' }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={'center'}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientEvaluations.map((row, index) => {
                    let tempDate = new Date(row.date)
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell key={`cell-${index}`} align={'center'}>
                          <Checkbox
                            checked={getIsCheckboxSelected(index)}
                            onChange={() => handleSelection(index)}
                            name="checkedA"
                            disabled={selectedEvals.length == 2}
                          />
                          {index}
                        </TableCell>
                        <TableCell key={row.date} align={'center'}>
                          {tempDate.toLocaleDateString('en-US', dateOptions)}
                        </TableCell>
                        <TableCell key={row.title} align={'center'}>
                          {row.results?.title ? row.results.title : 'none'}
                        </TableCell>
                        <TableCell key={row.fftx} align={'center'}>
                          {getFFTXMAxRepObjectWithIndex(index)}
                        </TableCell>
                        <TableCell key={row.ffty} align={'center'}>
                          {getFFTYMAxRepObjectWithIndex(index)}
                        </TableCell>
                        <TableCell key={row.hull} align={'center'}>
                          {Math.ceil(getHullAreaWithIndex(index))} mm
                          <label className="pow-number">2</label>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {/* {patientEvaluations && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={patientEvaluations.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )} */}
        </Grid>
        <Grid item lg="7">
          {selectedEvals.length > 0 && (
            <div className="hulls_div">
              {polygonsTitle && (
                <div className="hulls-title">
                  {polygonsTitle[0] && (
                    <label className="hull-title1">{polygonsTitle[0]}</label>
                  )}
                  {polygonsTitle[1] !== undefined && (
                    <label className="hull-title2">{polygonsTitle[1]}</label>
                  )}
                </div>
              )}
              <Scatterplot
                x={50}
                y={50}
                width={1000}
                height={500}
                data={polygon}
                data2={polygon2}
                hull={true}
              />
            </div>
          )}
        </Grid>

        {graphSeries && showSelectedEvalsGraph && (
          <div className="graph-container">
            <ReactApexChart
              options={graphOptions}
              series={graphSeries}
              type="line"
              height={500}
              width={window.innerWidth - 50}
            />
          </div>
        )}
      </Grid>
    </div>
  )
}

export default PatientScreen
