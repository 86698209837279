import React from 'react'
import  { UnityContext } from 'react-unity-webgl'
import GameContainer from '../GameContainer'

const unityContext = new UnityContext({
  loaderUrl: './rollaball/ballGame.loader.js',
  dataUrl: './rollaball/ballGame.data',
  frameworkUrl: './rollaball/ballGame.framework.js',
  codeUrl: './rollaball/ballGame.wasm',
})

export default function CubeGame() {
  return (
    <GameContainer unityContext={unityContext} />
  )
}