import { buf2hex, hexToBuf, normalizeSensorData } from './boardUtils'
import { BleBordChannel } from '../channels'

let BleConnection;
const DISCONNECT_EVENT = 'gattserverdisconnected';
const DATA_EVENT = 'characteristicvaluechanged';

const connectionData = {}

const disconnectHandler = (event) => {
  console.log('> Bluetooth Device disconnected')
}

const dataChangeHandler = (event) => {
  const buf = buf2hex(event.target.value.buffer)
  const points = normalizeSensorData({ x: buf[0], y: buf[1], z: buf[2], q: buf[3] })
  BleBordChannel.sendMessage(points)

}

export const disconnectBle = (cb = null) => {
  if (BleConnection) {
    console.log('Disconnecting from Bluetooth Device...')
    if (BleConnection.gatt.connected) {
      BleConnection.gatt.disconnect()
      cb && cb()
    } else {
      console.log('> Bluetooth Device is already disconnected')
    }
  }
}
export const sendBleCommand = async (command) => {
  // todo test with the board
  if (BleConnection) {
    const send = hexToBuf(command)
    try {
      let sentRes = await this.characteristicSend.writeValue(send)
      console.log('sent', sentRes)
    } catch (error) {
      console.log('Argh! ' + error)
    }
  }
}

export const connectBle = async (cb = null) => {
    BleConnection = await navigator.bluetooth.requestDevice({
      filters: [
        {
          name: 'ESP32',
        },
      ],
      optionalServices: [
        '00002a00-0000-1000-8000-00805f9b34fb',
        '0000ffe5-0000-1000-8000-00805f9a34fb',
      ],
    })
    BleConnection.addEventListener(DISCONNECT_EVENT, disconnectHandler)
    const server = await BleConnection.gatt.connect()
    const service = await server.getPrimaryService(
      '0000ffe5-0000-1000-8000-00805f9a34fb'
    )
    connectionData.characteristic = await service.getCharacteristic(
      '0000ffe4-0000-1000-8000-00805f9a34fb'
    )
    connectionData.characteristicSend = await service.getCharacteristic(
      '0000ffe9-0000-1000-8000-00805f9a34fb'
    )
    await connectionData.characteristic.startNotifications()

    connectionData.characteristic.addEventListener(DATA_EVENT, dataChangeHandler)
    cb && cb()
}
