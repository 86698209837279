import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { SET_PATIENT } from '../../constants/store'
import {
  Button,
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import GamesIcon from '@material-ui/icons/Games'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import AdjustIcon from '@material-ui/icons/Adjust'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'

import './therapist-screen.css'

const columns = [
  { id: 'Patient', label: 'Patient' },
  { id: 'Email', label: 'Email' },
  // { id: 'Rehab', label: 'Rehabilitation plan' },
  // { id: 'Track', label: 'Track progress' },
  // { id: 'Edit', label: 'Edit patient details' },
  { id: 'Select', label: 'Select Patient' },
]

const TherapistScreen = ({ setNav }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [gameModal, setGameModal] = useState(false)
  const [redirect, setRedirect] = useState(undefined)
  const [patients, setPatients] = useState([])

  useEffect(async () => {
    let response = await fetch(`https://bb.chepa.net/v1/users?role=patient`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const json = await response.json()
    setPatients(json)
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const rehabClicked = (id) => {
    console.log(`rehabilitation clicked with patient id: ${id}`)
  }

  const trackProgress = (id) => {
    console.log(`track progress clicked with patient id: ${id}`)
  }

  const editPatient = (id) => {
    console.log(`edit patient clicked with patient id: ${id}`)
  }

  const selectPatient = (patient) => {
    dispatch({
      type: SET_PATIENT,
      payload: patient,
    })
    setNav('patient')
  }

  const addpatientClicked = () => {
    alert(`add patient clicked, not implemented yet`)
  }

  const startGameClicked = () => {
    setGameModal(true)
    console.log(`start game clicked`)
  }

  const startEvaluationClicked = () => {
    console.log(`start evaluation clicked`)
    setRedirect('/demo')
  }

  const selectGame = (ind) => {
    setGameModal(false)
    if (ind) {
      console.log('Space game clicked')
      setRedirect('/spaceGame')
    } else {
      console.log('Cube game clicked')
      setRedirect('/game')
    }
  }

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <Paper className="therapist-container">
      <div className="patient-title">My Patients</div>
      <TableContainer className="patient-table">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={'center'}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {patients?.results?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell key={row.name} align={'center'}>
                    {row.name}
                  </TableCell>
                  <TableCell key={row.email} align={'center'}>
                    {row.email}
                  </TableCell>
                  {/* <TableCell key={row.Rehab} align={'center'}>
                    <AssignmentOutlinedIcon
                      style={{
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                      }}
                      onClick={() => rehabClicked(row.id)}
                    />
                  </TableCell>
                  <TableCell key={row.Track} align={'center'}>
                    <TrendingUpOutlinedIcon
                      style={{
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                      }}
                      onClick={() => trackProgress(row.id)}
                    />
                  </TableCell>
                  <TableCell key={row.Edit} align={'center'}>
                    <EditOutlinedIcon
                      style={{
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                      }}
                      onClick={() => editPatient(row.id)}
                    />
                  </TableCell> */}
                  <TableCell key={row.Select} align={'center'}>
                    <DoubleArrowIcon
                      style={{
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                      }}
                      onClick={() => selectPatient(row)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={patients?.results?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {/* game dialog */}
      <Dialog
        onClose={() => setGameModal(false)}
        aria-labelledby="simple-dialog-title"
        open={gameModal}>
        <DialogTitle id="simple-dialog-title">
          Wich game would you like to play?
        </DialogTitle>
        <div className="game-dialog">
          <div className="games-dialog-div">
            <div className="game-dialog-div" onClick={() => selectGame(0)}>
              <AdjustIcon
                style={{
                  color: '#deb887',
                  width: '50px',
                  height: '50px',
                  cursor: 'pointer',
                }}
              />
              <label className="game-dialog-label">Cube game</label>
            </div>
            <div className="game-dialog-div" onClick={() => selectGame(1)}>
              <FlightTakeoffIcon
                style={{
                  color: '#dc143c',
                  width: '50px',
                  height: '50px',
                  cursor: 'pointer',
                }}
              />
              <label className="game-dialog-label">Space game</label>
            </div>
          </div>
          <div className="dialog-butttons">
            <Button
              variant="contained"
              color="primary"
              className="dialog-buttton"
              onClick={() => setGameModal(false)}>
              close
            </Button>
          </div>
        </div>
      </Dialog>
    </Paper>
  )
}

export default TherapistScreen
