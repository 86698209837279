import React from 'react'
import  { UnityContext } from 'react-unity-webgl'
import GameContainer from '../GameContainer'

const unityContext = new UnityContext({
  loaderUrl: './spaceGame/spaceGameWeb.loader.js',
  dataUrl: './spaceGame/spaceGameWeb.data',
  frameworkUrl: './spaceGame/spaceGameWeb.framework.js',
  codeUrl: './spaceGame/spaceGameWeb.wasm',
})

export default function SpaceGame() {
  return (
    <GameContainer unityContext={unityContext} />
  )
}

