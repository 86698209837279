import React from 'react'
import * as d3 from 'd3'

import Axis from './Axis'

class Scatterplot extends React.PureComponent {
  state = {
    xScale: d3.scaleLinear().domain([-210, 210]).range([0, this.props.width]),
    yScale: d3.scaleLinear().domain([-150, 150]).range([this.props.height, 0]),
  }

  static getDerivedStateFromProps(props, state) {
    const { yScale, xScale } = state
    const { hull } = props

    yScale.range([props.height, 0])
    xScale.range([0, props.width])

    return {
      ...state,
      yScale,
      xScale,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { x, y, data, data2, height, zoom } = this.props,
      { yScale, xScale } = this.state

    if (prevProps.zoom !== zoom){

    }

    if (prevProps.data !== data || prevProps.data2 !== data2) {
      if (data.length > 1) {
        d3.select('#hull')
          .selectAll('circle')
          .data(data)
          .join('circle')
          .attr('r', 1)
          .attr('cx', (d) => xScale(d[0]))
          .attr('cy', (d) => yScale(d[1]))

        var Gen = d3
          .line()
          .curve(d3.curveLinearClosed)
          .x((p) => xScale(p[0]))
          .y((p) => yScale(p[1]))

        d3.select('#hull')
          .append('path')
          .attr('d', Gen(data))
          .attr('fill', 'none')
          .attr('stroke', 'green')

        //   let hull = d3.polygonHull(data)
        //   let line = d3.line().curve(d3.curveLinearClosed)

        //   d3.select('#hull')
        //     .append('path')
        //     .attr('d', line(hull))
        //     .attr('stroke-dasharray', '1')
        //     .attr('fill', 'none')
        //     .attr('stroke', 'black')
      }

      if (data2?.length > 1) {
        d3.select('#hull2')
          .selectAll('circle')
          .data(data2)
          .join('circle')
          .attr('r', 1)
          .attr('cx', (d) => xScale(d[0]))
          .attr('cy', (d) => yScale(d[1]))
          .call(d3.zoom().on("zoom", function (event) {
            svg.attr("transform", event.transform)
         }))

        var Gen = d3
          .line()
          .curve(d3.curveLinearClosed)
          .x((p) => xScale(p[0]))
          .y((p) => yScale(p[1]))

        d3.select('#hull2')
          .append('path')
          .attr('d', Gen(data2))
          .attr('fill', 'none')
          .attr('stroke', 'red')

      }
    }
  }

  render() {
    const { x, y, data, height, hull, width } = this.props,
      { yScale, xScale } = this.state
    return (
      <svg width="100%" height="600" >
        {hull ? <g id="hull" transform="scale(4)"  transform={`translate(${x}, ${y})`}></g> : null}
        {hull ? <g id="hull2" transform={`translate(${x}, ${y})`}></g> : null}
        <g transform={`translate(${x}, ${y})`}>
          <rect
            x={hull ? 24 : 45}
            y={hull ? 35 : 35}
            width={hull ? '952' : '1760'}
            height="435"
            style={{
              fill: 'rgb(0,0,0,0)',
              strokeWidth: '2',
              stroke: 'rgb(0,0,0)',
            }}
          />
          {data?.length == 1 ? data.map(([x, y], index) => (
            <circle
              key={index}
              cx={xScale(x)}
              cy={yScale(y)}
              r={!hull ? 8 : 1}
            />
          )) : null}
          <Axis
            x={0}
            y={0}
            scale={yScale}
            type="Left"
            label={'X axis(mm)'}
          />
          <Axis
            x={0}
            y={height}
            scale={xScale}
            type="Bottom"
            label={'Y axis(mm)'}
          />
        </g>
      </svg>
    )
  }
}

export default Scatterplot
